import React from 'react';
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import 'antd/dist/antd.less';
import './Layout.css';
import AdminLayout from './AdminLayout';

import {useState} from 'react';
import {Button, Input} from 'antd';
import {navigate} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import useBreakpoint from '../Hooks/useBreakPoint';
import ReactDelighters from './ReactDelighters';
import {Store} from '@styled-icons/material';

import Logo from '../images/LogoLight.png';

import useGoogleAnalytics from '../Hooks/useGoogleAnalytics';

import MessengerCustomerChat from 'react-messenger-customer-chat';

const SEOInfo = {
  title: 'FreeStay 雲端排號助手',
  image: 'https://freestay.revtel.app/images/Logo.png',
  description:
    '最小化顧客店內等待時間！全雲端免安裝 | 易於上手操作 | 顧客自行分流',
};

function FBMessenger({path}) {
  if (path.indexOf('admin')) {
    return <div />;
  } else {
    return (
      <MessengerCustomerChat
        pageId="107020738243390"
        appId="<APP_ID>"
        htmlRef={path}
      />
    );
  }
}

function Layout({children, location}) {
  useGoogleAnalytics({gaId: 'G-G8PZV8XSM8'});
  if (location.pathname.indexOf('admin') > -1) {
    return <AdminLayout location={location}>{children}</AdminLayout>;
  }

  return (
    <>
      <Helmet>
        <title>FreeStay</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />

        {/* ---- seo ---- */}
        <meta name="description" content={SEOInfo.description} />

        <meta name="image" content={SEOInfo.image} />

        <meta property="og:title" content={SEOInfo.title} />

        <meta property="og:description" content={SEOInfo.description} />

        <meta property="og:image" content={SEOInfo.image} />
        <meta name="twitter:title" content={SEOInfo.title} />

        <meta name="twitter:description" content={SEOInfo.description} />

        <meta name="twitter:image" content={SEOInfo.image} />
        {/* ------------- */}
      </Helmet>
      <Wrapper>
        <ContentWrapper>{children}</ContentWrapper>
      </Wrapper>
    </>
  );
}

function ContentWrapper({children}) {
  const [user] = useOutlet('user');
  const [actions] = useOutlet('actions');
  const showLoginModal = useOutletSetter('login-modal');
  const {passBreakpoint} = useBreakpoint(100);
  const [storeId, setStoreId] = useState();

  return (
    <ReactDelighters>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          paddingTop: 64,
          width: '100%',
        }}>
        <NavBar
          hasBorder={passBreakpoint}
          bgColor={passBreakpoint ? 'white' : 'transparent'}
          style={{height: 64}}>
          <a href="/">
            <img src={Logo} style={{height: 40}} />
          </a>
          {user ? (
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Button type="text" onClick={() => navigate('/admin')}>
                進入後台
              </Button>
              <Button onClick={() => actions.logout()}>登出</Button>
            </div>
          ) : (
            <div style={{}}>
              <Button
                onClick={() => showLoginModal(true)}
                style={{marginLeft: 10}}>
                馬上行動！
              </Button>
            </div>
          )}
        </NavBar>

        <div
          style={{
            display: 'flex',
            flex: 1,
            backgroundColor: '#EEEEEE',
          }}>
          {children}
        </div>

        <Footer bgColor="white">
          <p style={{width: '80%', textAlign: 'center'}}>
            @ 2023 FreeStay, all rights reserved. <br />
            <a
              href="/license"
              target="_blank"
              style={{textDecoration: 'none', color: '#a5a58d'}}
              rel="noreferrer">
              使用條款及隱私權政策
            </a>
            <br />
            <a
              href="https://revteltech.pse.is/freestay-back-home"
              target="_blank"
              style={{textDecoration: 'none', color: '#561E03'}}
              rel="noreferrer">
              Created by RevtelTech
            </a>
          </p>
        </Footer>
      </div>
    </ReactDelighters>
  );
}

const NavBar = styled.div`
  position: fixed;
  background-color: ${(props) => props.bgColor};
  top: 0px;
  left: 0px;
  width: 100%;
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  box-shadow: ${(props) =>
    props.hasBorder
      ? '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)'
      : 'none'};
  transition: 200ms;
`;

const Footer = styled.div`
  width: 100%;
  padding: 15px;
  background-color: ${(props) => props.bgColor};
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div``;

export default Layout;
